/**
 * Add margin to footer for fixed 'add to cart' div o mobile
 */
const addToCartDiv = document.querySelector('.product-add-to-cart')
if (addToCartDiv) {
    handleFooterMargin();
    window.addEventListener("resize", handleFooterMargin);
}

function handleFooterMargin() {
    const footer = document.querySelector('.footer-container')
    if (window.matchMedia("(max-width: 768px)").matches && addToCartDiv) {
        const divHeight = addToCartDiv.offsetHeight
        footer.style.marginBottom = "100px"
    } else {
        footer.style.marginBottom = "0"
    }
}
