// const searchbar = document.querySelector('.menu_top_searchbar')
// console.log(searchbar)
// const searchbarResults = document.querySelector('ul.searchbar-autocomplete')

// const opensearchbarModalIcon = document.querySelector('.menu_top_searchbar_mobile_icon')
// const closeSearchbarModalIcon = document.querySelector('.close_searchbar_mobile_modal')

// function toggleSearchbar() {
//   searchbar.classList.toggle('active')
//   document.querySelector('.ui-autocomplete-input').focus()
// }

// opensearchbarModalIcon && opensearchbarModalIcon.addEventListener('click', () => toggleSearchbar())
// closeSearchbarModalIcon && closeSearchbarModalIcon.addEventListener('click', () => toggleSearchbar())

// Hamburger
// const menuBottomContainer = document.querySelector('#menu_bottom_container')
// if (menuBottomContainer) {
//   const ham = document.querySelector('.ham')
//   ham.addEventListener('click', () => {
//     ham.classList.toggle('active')
//     menuBottomContainer.classList.toggle('active')
//   })
// }

// Langage dropdown
const currentLangage = document.querySelector('img.current_lang')
if (currentLangage) {
  const langagesDropdown = document.querySelector('.langages_dropdown')
  // console.log(langagesDropdown)
  currentLangage.addEventListener('click', () => langagesDropdown.classList.toggle('active'))
}

// Mobile Submenu togglers
// const submenuTogglers = document.querySelectorAll('.menu_sub_arrow')
// submenuTogglers &&
//   submenuTogglers.forEach((submenuToggler) =>
//     submenuToggler.addEventListener('click', () => {
//       const index = submenuToggler.dataset.subIndex

//       const target = document.querySelector('.submenu[data-sub-index="' + index + '"]')
//       target.classList.toggle('active')
//     })
//   )

// // Menu position on scroll
// const menu = document.querySelector('#mdigimenu')
// // const menuMobile = document.querySelector('#menu-icon')
// const firstBurger = document.querySelector('.burger_container')

// function handleSticky() {
//     // let offset = menu.offsetHeight;
//     let offset = menu.offsetHeight;

//     if (window.scrollY > offset - 10) {
//         menu.classList.add("stickit");
//         firstBurger.style.cssText = `margin-top: ${offset}px !important`
//     } else if (window.scrollY < offset - 20) {
//         menu.classList.remove("stickit");
//         firstBurger.style.marginTop = 0
//     }
// }
// window.addEventListener("scroll", () => handleSticky());

// New menu

/**
 * @description Toggles active state
 * @param {*} el
 * @param {*} action (default: 'toggle')
 */
const toggleActive = (el, action) => {
  switch (action) {
    case 'add':
      if (!el.classList.contains('active')) el.classList.add('active')
      break
    case 'remove':
      if (el.classList.contains('active')) el.classList.remove('active')
      break
    default:
      el.classList.toggle('active')
      break
  }
}

// Hamburger
const menuWrapperMobile = document.querySelector('#menu_wrapper_mobile')
const menuLinksMobile = document.querySelector('#menu_links_mobile')

if (menuWrapperMobile) {
  const ham = document.querySelector('.ham')
  ham.addEventListener('click', () => {
    toggleActive(ham)
    toggleActive(menuLinksMobile)
  })
}

// Searchbar
const searchbar = document.querySelector('.menu_top_searchbar')
const opensearchbarModalIcon = [...document.querySelectorAll('.menu_top_searchbar_mobile_icon')]
const closeSearchbarModalIcon = document.querySelector('.close_searchbar_mobile_modal')

function toggleSearchbar() {
  toggleActive(searchbar)
  document.querySelector('.ui-autocomplete-input').focus()
}

opensearchbarModalIcon &&
  opensearchbarModalIcon.forEach((icon) => icon.addEventListener('click', () => toggleSearchbar()))
closeSearchbarModalIcon && closeSearchbarModalIcon.addEventListener('click', () => toggleSearchbar())

// Mobile Submenu togglers
const submenuTogglers = document.querySelectorAll('.menu_sub_arrow')
submenuTogglers &&
  submenuTogglers.forEach((submenuToggler) =>
    submenuToggler.addEventListener('click', () => {
      const index = submenuToggler.dataset.subIndex

      const target = document.querySelector('.submenu_mobile[data-sub-index="' + index + '"]')
      // console.log(target)
      toggleActive(target)
    })
  )

// Desktop menu / submenu logic
const menuItems = document.querySelectorAll('.menu_item')
menuItems &&
  menuItems.forEach((item) => {
    const index = item.dataset.index
    // console.log(index)
    const submenu = document.querySelector('.submenu[data-sub-index="' + index + '"]')
    // console.log(submenu)
    if (submenu) {
      item.addEventListener('mouseover', () => toggleActive(submenu, 'add'))
      item.addEventListener('mouseout', () => toggleActive(submenu, 'remove'))

      submenu.addEventListener('mouseover', () => toggleActive(submenu, 'add'))
      submenu.addEventListener('mouseout', () => toggleActive(submenu, 'remove'))
    }
  })
