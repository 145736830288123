import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const animatedTexts = [...document.querySelectorAll('.banneranim')];

// Sinple Text : Text outline version
if (animatedTexts.length !== 0) {
    animatedTexts.forEach((at) => {
        // Gsap animation only if class of 'outline'
        const sloganTxt = at.querySelector('.slogan').dataset.slogan;
        const outline = at.querySelectorAll('.outline');

        // Fill with slogan text (5 times) todo : calculate slogan text length and fill enough depending on length
        outline.forEach((ol) => {
            for (let i = 0; i < 6; i++) {
                ol.textContent += sloganTxt;
            }
        });

        // Gsap animation crossing
        const textOutlineTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: at,
                start: 'top bottom',
                end: 'bottom top',
                scrub: 1
            }
        });

        const speed = 600;
        textOutlineTimeline.to(outline[0], { left: -speed, duration: 3 });
        textOutlineTimeline.to(outline[1], { left: speed, duration: 3, delay: -3 });
        textOutlineTimeline.to(outline[2], { left: -speed, duration: 3, delay: -3 });
        textOutlineTimeline.to(outline[3], { left: speed, duration: 3, delay: -3 });
    });
}

